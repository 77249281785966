// Слайдеры

$('.slider').slick({
    dots: false,
    arrows: true,
    speed: 1900,
    // autoplay: true,
    autoplaySpeed: 3000,
    responsive: [{
        breakpoint: 576,
        settings: {
            arrows: false,
        }
    }]
});

$('.product__slider').slick({
    dots: false,
    arrows: true,
    speed: 1000,
    // autoplay: true,
    autoplaySpeed: 3000,
    responsive: [{
        breakpoint: 576,
        settings: {
            arrows: false,
        }
    }]
});

$('.products__slider').slick({
    dots: false,
    arrows: true,
    speed: 1000,
    // autoplay: true,
    autoplaySpeed: 3000,
    adaptiveHeight: true,
    responsive: [{
        breakpoint: 576,
        settings: {
            arrows: true,
        }
    }]
});

$('.certificates__slider').slick({
    dots: false,
    arrows: true,
    speed: 900,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [{
            breakpoint: 991,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                arrows: false,
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
            }
        }

    ]
});

$('.projects__slider').slick({
    dots: false,
    arrows: true,
    speed: 900,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [{
            breakpoint: 991,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
            }
        },
    ]
});


// Плавный скрол

var $page = $("html, body");
$('a[href*="#"]').click(function() {
    return $page.animate({
        scrollTop: $($.attr(this, "href")).offset().top
    }, 1400), !1
})

// Табы

$.ionTabs("#tabs_1");

// Акардеон

$(function($) {
    $('.internal__more').click(function() {
        $(this).next().slideToggle(500);
        $(this).toggleClass('open');
    });
});

$(function($) {
  var allAccordions = $('.accordion div.data');
  var allAccordionItems = $('.accordion .openable');
  $('.accordion > .openable').click(function() {
    if($(this).hasClass('open'))
    {
      $(this).removeClass('open');
      $(this).next().slideUp("slow");
    }
    else
    {
    allAccordions.slideUp("slow");
    allAccordionItems.removeClass('open');
    $(this).addClass('open');
    $(this).next().slideDown("slow");
    return false;
    }
  });
});

// Модальное окно

$('.open_modal').click(function() {
    $('#form_modal').arcticmodal();
});